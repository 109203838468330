import axios from "axios";
import store from './../index'

export default {
    state: {
        currentLanguage: JSON.parse(localStorage.getItem('CURRENT_LANG')),
        languages: [],
        stringValues: []
    },
    getters: {
        CURRENT_LANG: state => {
            return state.currentLanguage
        },
        LANGUAGES: state => {
            return state.languages
        },
        STRING_VALUES: state => {
            return state.stringValues
        },
    },
    mutations: {
        SET_LANGUAGES: (state, payload) => {
            state.languages = payload
        },
        SET_CURRENT_LANG: (state, payload) => {
            localStorage.setItem('CURRENT_LANG', JSON.stringify(payload))
            state.currentLanguage = payload
        },
        SET_STRING_VALUES: (state, payload) => {
            state.stringValues = payload
        },
    },
    actions: {
        GET_ALL_LANGUAGES: () => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Language/GetLanguages`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200){
                            store.commit('SET_LANGUAGES', data)
                            resolve(true)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        COMMIT_STRING_VALUES: (commit, payload) => {
            store.commit('SET_STRING_VALUES', payload)
        },
    }
}