<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <div class="ma-3">
        <v-btn text v-on="on">
          <v-icon color="primary" left>mdi-earth</v-icon>
          <span class="text-capitalize primary--text font-weight-bold">{{
            currentLang.language
          }}</span>
        </v-btn>
      </div>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="item in allLanguages"
          :key="item.id"
          @click="item.click"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    allLanguages() {
      let items = [];
      let langs = this.$store.getters.LANGUAGES;

      for (let i in langs) {
        let obj = {
          id: langs[i].id,
          title: langs[i].language,
          click: () => {
            window.console.log(langs[i] + " asd");
            this.$store.commit("SET_CURRENT_LANG", langs[i]);
          },
        };

        items.push(obj);
      }
      return items;
    },
    currentLang() {
      let cLang = this.$store.getters.CURRENT_LANG;
      if (cLang === null || cLang === undefined) {
        cLang = this.$store.getters.LANGUAGES[0];
        this.$store.commit("SET_CURRENT_LANG", cLang);
      }
      window.console.log(cLang + " wsd");
      return cLang;
    },
  },
  async mounted() {
    await this.$store
      .dispatch("GET_ALL_LANGUAGES")
      .then(() => {})
      .catch((error) => {
        window.console.log(error);
      });
  },
};
</script>
